<template class="tinted-image">
  <div id="page-login">
    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-justify="right" vs-align="center" vs-xs="12" vs-w="4">
        <p class="p-6 img-banner-home">
          <img v-if="recoverPass" src="images/login-550x550.png" />
          <img v-if="!recoverPass" src="images/recover-pass-550.png" />
        </p>
      </vs-col>
      <vs-col vs-justify="left" vs-align="center" vs-xs="12" vs-w="4">
        <!-- login -->
        <form method="POST">
          <div
            class="p-6 mt-12"
            v-if="recoverPass"
            @keydown.enter="openLoadingContained"
          >
            <h1>Ingreso</h1>
            <vs-input
              label-placeholder="E-mail"
              v-model="uMail"
              type="email"
              v-validate="'required|email'"
              name="mail"
              color="warning"
              class="w-full mt-8 mb-8"
            />
            <vs-input
              type="password"
              label-placeholder="Contraseña"
              v-model="uPass"
              color="warning"
              class="w-full -mt-6 mb-4"
              v-validate="'required|min:4'"
              name="pass"
            />
            <vs-button
              class="w-full btn-black r-30 mb-2"
              :disabled="!blockLogin"
              @click="loginUser"
              ref="loadableButton"
              id="button-with-loading"
              >Ingresar
              <img src="images/siguiente.png" class="ml-3" width="50" />
            </vs-button>

            <div v-if="recoverPass">
              <a
                class="customLink mt-2"
                v-on:click="
                  (recoverPass = false), (rMail = uMail), validateResetEmail()
                "
                >Olvid&eacute; mi contrase&ntilde;a</a>
            </div>
          </div>
        </form>

        <hr style="margin: 8px auto 20px; border-color: #888888">

        <form method="GET" id="sso_form" :action="apiUrl + '/login/sso'">
          <div class="p-6">
             <vs-button
             class="w-full btn-black r-30 mb-2"
             @click="loginUserWithAzure"
             ref="loadableButton"
            >Ingresar con Azure
               <img src="images/siguiente.png" class="ml-3" width="50" />
            </vs-button>
          </div>
        </form>

        <!-- recover pass -->
        <form>
          <div class="p-6 mt-12" v-if="!recoverPass">
            <h1>Recuperar Contraseña</h1>
            <vs-input
              label-placeholder="E-mail"
              v-model="rMail"
              type="email"
              v-validate="'required|email'"
              name="correo"
              color="warning"
              class="w-full mt-6 mb-3"
            />

            <p style="color: #ff0000; padding-bottom: 5px">
              {{ errorMessage }}
            </p>
            <p>
              <vs-button
                class="w-full btn-black r-30 mb-2"
                ref="loadableButton2"
                id="button-with-loading2"
                v-on:keyup.enter="resetPassword"
                @click="resetPassword"
                color="warning"
                :disabled="!v_rMail"
                >Recuperar
                <img src="images/siguiente.png" width="50" />
              </vs-button>
            </p>

            <div v-if="!recoverPass">
              <a
                class="customLink customWidthRestoreLink mt-2"
                v-on:click="recoverPass = true"
              >
                <img src="images/atras.png" width="30" /> &nbsp; Ir a Login
              </a>
            </div>
          </div>
        </form>

        <vs-divider position="center">
          <img src="images/logo-uncierre-514x114.png" alt="Logo Uncierre" />
        </vs-divider>
      </vs-col>
    </vs-row>

    <vs-row vs-type="flex" vs-justify="center">
      <vs-col vs-justify="right" vs-align="center" vs-w="8">
        <ul class="list-uncierre text-right">
          <li>Administra,</li>
          <li>agiliza,</li>
          <li>y facilita</li>
          <li><strong>tus cierres.</strong></li>
        </ul>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { email } from "../../../services";

export default {
  data() {
    return {
      uMail: "",
      uPass: "",
      rMail: "",
      recoverPass: true,
      v_uMail: false,
      v_uPass: false,
      v_rMail: false,
      backgroundLoading: "warning",
      colorLoading: "#000",
      errorMessage: "",
      apiUrl: process.env.VUE_APP_API_URL,
    };
  },
  mounted() {
    const queryString = window.location.search;

    const params = new URLSearchParams(queryString);
    const token = params.get("tk");

    if (token)
      this.loginSSO(token);
  },
  methods: {
    openLoadingContained() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading > .con-vs-loading");
      }, 3000);
    },
    validateResetEmail() {
      if (this.uMail == "") {
        this.v_rMail = false;
      } else {
        this.v_rMail = true;
      }
    },
    async openLoadingContainedRestorePass() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading2",
        scale: 0.45,
      });
      setTimeout(() => {
        this.$vs.loading.close("#button-with-loading2 > .con-vs-loading");
      }, 4000);
      await this.resetPassword();
    },

    async loginUser() {
      this.openLoadingContained();
      try {
        const account = await this.$store.dispatch("auth/login", {
          email: this.uMail,
          password: this.uPass,
        });
        this.$vs.notify({
          title: `🖐 Hola ${account.user.first_name}`,
          text: `Su conexion es segura 🔐.`,
          color: "success",
        });

        if (this.$router.history.current.query.from) {
          this.$router.push(this.$router.history.current.query.from);
        } else {
          window.location.href = "/panel-desarrolladora";
        }
      } catch (err) {
        this.$vs.notify({
          title: "🔐Acceso",
          text: "Usuario o contraseña invalida",
          color: "danger",
        });
      }
    },

    loginUserWithAzure() {
      document.querySelector('#sso_form').submit();
    },
    async loginSSO(token) {
      this.openLoadingContained();

      try {
        const account = await this.$store.dispatch("auth/loginSSO", {
          token: token
        });
        this.$vs.notify({
          title: `🖐 Hola ${account.user.first_name}`,
          text: `Su conexion es segura 🔐.`,
          color: "success",
        });

        if (this.$router.history.current.query.from) {
          this.$router.push(this.$router.history.current.query.from);
        } else {
          window.location.href = "/panel-desarrolladora";
        }
      } catch (err) {
        this.$vs.notify({
          title: "🔐Acceso",
          text: "Usuario o contraseña invalida",
          color: "danger",
        });
      }
    },

    async resetPassword() {
      try {
        this.errorMessage = "";
        await email.sendEmail("reset_password", `{"email": "${this.rMail}"}`);
        this.$vs.notify({
          title: "📩Revisa tu bandeja de entrada",
          text: "Si tu usuario es correcto, recibirás un email con los detalles",
          color: "success",
        });
      } catch (error) {
        this.errorMessage =
          "Ha ocurrido un error al reiniciar contraseña, por favor verifica tu correo electrónico o intenta de nuevo más tarde.";
      }
    },
  },
  watch: {
    uMail() {
      this.$validator.validate("mail").then((result) => {
        if (result) {
          this.v_uMail = true;
        } else {
          this.v_uMail = false;
        }
      });
    },
    uPass() {
      this.$validator.validate("pass").then((result) => {
        if (result) {
          this.v_uPass = true;
        } else {
          this.v_uPass = false;
        }
      });
    },
    rMail() {
      this.$validator.validate("correo").then((result) => {
        if (result) {
          this.v_rMail = true;
        } else {
          this.v_rMail = false;
        }
      });
    },
  },
  computed: {
    blockLogin() {
      return this.v_uMail === true, this.v_uPass === true;
    },
  },
};
</script>
<style lang="scss">
#page-login {
  background-color: #fff;
  padding: 30px 0;

  .r-30 {
    border-radius: 30px;
  }

  .customLink {
    color: #000;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    display: block;
  }

  .customLink img {
    display: inline;
  }

  .btn-black {
    background: #000 !important;
  }

  button img {
    display: inline;
  }

  input {
    border-radius: 30px;
    background: #f2f4f6;
    color: #111 !important;
  }

  @media (min-width: 300px) and (max-width: 776px) {
    .img-banner-home {
      display: none;
    }
  }
}

.customLink {
  color: #000;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  display: block;
}

.customLink img {
  display: inline;
}

.btn-black {
  background: #000 !important;
}

button img {
  display: inline;
}

input {
  border-radius: 30px;
  background: #f2f4f6;
  color: #111 !important;
}

@media (min-width: 300px) and (max-width: 776px) {
  .img-banner-home {
    display: none;
  }
}
</style>
